/* -*- JS -*- */
// ------------------------------------------------------------------------
//
// This code is property of BioSilico Limited and is protected by
// copyright law and international treaties. Unauthorized
// reproduction or distribution of this file, or any portion of
// it may result in severe civil and criminal penalties, and will
// be prosecuted to the maximum extent possible under the law.
//
// = LIBRARY
//   HomepageJS
//
// = FILENAME
//   WAsmReactComponent.js
//
// = AUTHOR
//   Who       When        Why/What
//   K. Plewa   09.11.2022  Development
//
// ------------------------------------------------------------------------
import React from "react";
import { Auth } from "aws-amplify";
export default class WasmReactComponent extends React.Component {
  constructor() {
    super();
    window.wasmReactComponent = this;
  }

  // ----------------------------------------------------------------------------
  detectIsMobile() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }

  // ----------------------------------------------------------------------------
  detectSmallScreen() {
    return window.innerWidth <= 640;
  }

  // ----------------------------------------------------------------------------
  signOut = async () => {
    try {
      console.log("Sign out");
      sessionStorage.removeItem("userLoggedIn");
      await Auth.signOut();
      return true;
    } catch (error) {
      console.log("error signing out: ", error);
      return false;
    }
  };

  // ----------------------------------------------------------------------------
  removeLeavePageListener() {
    window.onbeforeunload = null;
  }

  // ----------------------------------------------------------------------------
  addLeavePageListener() {
    window.onbeforeunload = function (e) {
      return "Are you sure you want to leave?";
    };
  }

  // ---------------------------------------------------------------------------
  componentDidMount() {
    window.onbeforeunload = function (e) {
      return "Are you sure you want to leave?";
    };
  }

  // ----------------------------------------------------------------------------
  render() {
    const quit = document.querySelector("#root");
    quit.addEventListener("input", (event) => {
      if (event.target.value !== "") {
        window.addEventListener("beforeunload", this.addLeavePageListener(), {
          capture: true,
        });
      } else {
        window.removeEventListener(
          "beforeunload",
          this.removeLeavePageListener(),
          {
            capture: true,
          }
        );
      }
    });
    return null;
  }
}
