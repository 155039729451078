// ------------------------------------------------------------------------
//
// This code is property of BioSilico Limited and is protected by
// copyright law and international treaties. Unauthorized
// reproduction or distribution of this file, or any portion of
// it may result in severe civil and criminal penalties, and will
// be prosecuted to the maximum extent possible under the law.
//
// = LIBRARY
//   HomepageJS
//
// = FILENAME
//   wasm.jsx
//
// = AUTHOR
//   Who       When        Why/What
//   K. Plewa   09.11.2022  Development
//
// ------------------------------------------------------------------------

import { React, useEffect } from "react";

// ------------------------------------------------------------------------
export default function IdeaMapper(props) {
  const loadWASM = async () => {
    const script = document.createElement("script");
    var scriptSRC;
    try {
      scriptSRC = eval(props.wasmsrcurl);
    } catch (error) {
      console.log(error);
      scriptSRC = props.wasmsrcurl;
    }
    script.src = scriptSRC;
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      var spinner = document.querySelector("#qtspinner");
      var canvas = document.querySelector("#qtcanvas");
      var status = document.querySelector("#qtstatus");
      var config = {
        canvasElements: [canvas],
        showLoader: function (loaderStatus) {
          spinner.style.display = "block";
          canvas.style.display = "none";
          status.innerHTML = loaderStatus + "...";
        },
        showError: function (errorText) {
          status.innerHTML = errorText;
          spinner.style.display = "block";
          canvas.style.display = "none";
        },
        showExit: function () {
          status.innerHTML = "Application exit";
          if (qtLoader.exitCode !== undefined)
            status.innerHTML += " with code " + qtLoader.exitCode;
          if (qtLoader.exitText !== undefined)
            status.innerHTML += " (" + qtLoader.exitText + ")";
          spinner.style.display = "block";
          canvas.style.display = "none";
        },
        showCanvas: function () {
          spinner.style.display = "none";
          canvas.style.position = "absolute";
          canvas.style.display = "block";
          return canvas;
        },
      };
      var qtLoader = window.QtLoader(config);
      var emscriptenSRC;
      try {
        emscriptenSRC = eval(props.wasmemscriptenstring);
      } catch (error) {
        console.log(error);
        emscriptenSRC = props.wasmemscriptenstring;
      }
      qtLoader.loadEmscriptenModule(emscriptenSRC);
    };
  };

  // ------------------------------------------------------------------------

  useEffect(() => {
    loadWASM();
  }, []);

  // ------------------------------------------------------------------------

  return (
    <div>
      <figure style={{ overflow: "visible" }} id="qtspinner">
        <center style={{ marginTop: "1.5em", lineHeight: "150%" }}>
          <img
            src="IdeaMapperLogo.svg"
            width={window.innerWidth / 1.35}
            height={window.innerHeight / 1.35}
            style={{ display: "block" }}
            alt=""
          ></img>
          <strong>Please wait while we get you set up!</strong>
          <div id="qtstatus"></div>
          <noscript>
            JavaScript is disabled. Please enable JavaScript to use this
            application.
          </noscript>
        </center>
      </figure>
      <canvas
        id="qtcanvas"
        contentEditable="true"
        onContextMenu={(e) => e.preventDefault()}
        style={{ width: "100vw", height: "100vh", display: "none" }}
        {...props}
      ></canvas>
    </div>
  );
}

// ------------------------------------------------------------------------
