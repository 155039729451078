/* -*- JS -*- */
// ------------------------------------------------------------------------
//
// This code is property of BioSilico Limited and is protected by
// copyright law and international treaties. Unauthorized
// reproduction or distribution of this file, or any portion of
// it may result in severe civil and criminal penalties, and will
// be prosecuted to the maximum extent possible under the law.
//
// = LIBRARY
//   HomepageJS
//
// = FILENAME
//   AuthenticatorComponent.jsx
//
// = AUTHOR
//   Who       When        Why/What
//   K. Plewa   09.11.2022  Development
//
// ------------------------------------------------------------------------

import React from "react";
import "@aws-amplify/ui-react/styles.css";
import "./AuthenticatorComponent.css";
import { Authenticator } from "@aws-amplify/ui-react";
import $ from "jquery";

// ------------------------------------------------------------------------
// Create a separate component from Amplify Authenticator to prevent a render within a
// render in the LoginComponent that is caused by Amplify Authenticator
export default class AuthenticatorComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      tokenExpiry: null,
      user: null,
    };
  }

  // ------------------------------------------------------------------------
  // ONLY after the component has updated send data to parent component to
  // stop sending data twice in case of re-renders. Need to send data only
  // once so that the user cloud is called only once
  componentDidUpdate() {
    this.passDataToLogInComponent(this.state.user);
  }

  // ------------------------------------------------------------------------
  // For sanity and prevent re-renders check if the component shoud update
  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.tokenExpiry !== nextState.tokenExpiry) {
      return true;
    } else {
      return false;
    }
  }

  // ------------------------------------------------------------------------
  authenticatorRemoveLeavePageListener() {
    window.wasmReactComponent.removeLeavePageListener();
  }

  // ------------------------------------------------------------------------
  // Pass the token email and users name and surname to main.js to be saved
  // and passed to wasm after it has been loaded
  passDataToLogInComponent(data) {
    console.log(data.signInUserSession);
    sessionStorage.setItem("userLoggedIn", true);
    this.props.passBack(data);
    window.wasmReactComponent.addLeavePageListener();
  }

  // ------------------------------------------------------------------------
  render() {
    return (
      <div className="signIn_containers">
        <Authenticator
          onStateChange={this.authenticatorRemoveLeavePageListener()}
          className="amazon"
          socialProviders={["google", "facebook"]}
        >
          {({ user }) => {
            if (user.username !== "" || user !== null) {
              $(".signIn_container").attr("style", "display: none;");
              this.setState(
                {
                  tokenExpiry: user.signInUserSession.idToken.payload.exp,
                  user: user,
                },
                () => this.props.setShowSignInPage(false)
              );
            }
          }}
        </Authenticator>
      </div>
    );
  }
}
// ------------------------------------------------------------------------
