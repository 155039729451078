/* -*- JS -*- */
// ------------------------------------------------------------------------
//
// This code is property of BioSilico Limited and is protected by
// copyright law and international treaties. Unauthorized
// reproduction or distribution of this file, or any portion of
// it may result in severe civil and criminal penalties, and will
// be prosecuted to the maximum extent possible under the law.
//
// = LIBRARY
//   HomepageJS
//
// = FILENAME
//   exchangeUserToken.js
//
// = AUTHOR
//   Who       When        Why/What
//   K. Plewa   09.11.2022  Development
//
// ------------------------------------------------------------------------
import axios from "axios";

// ------------------------------------------------------------------------
//decripting jwt
function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

// ------------------------------------------------------------------------
// Do the exchage of idToken from provider for ideaMapperToken
export default async function exchangeUserToken(token, provider) {
  // Set up the data for the cloud call
  let url = sessionStorage.getItem("userCloudURL").toString() + "exchangetoken";
  let tokenData = JSON.stringify({
    token: token,
    provider: provider,
  });
  // Create a config from data above for axios call
  let config = {
    method: "post",
    url: url,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: tokenData,
  };
  let error = false;
  // Make the axios call to the cloud to exchangeToken
  const {
    data: { accessToken },
  } = await axios(config).catch(function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error);
    alert(
      "There was a problem with logging you in to IdeaMapper Cloud.\n The page will now refresh, please log in again."
    );
    alert(`\nError: ${error.message}`);
    error = true;
  });
  if (error) {
    await window.wasmReactComponent.signOut();
    window.location.reload();
  }
  // If the idToken provider is Google then extract name
  // and last name and email from ideaMapperToken
  if (provider === "google") {
    let { name, lastName, email } = parseJwt(accessToken);
    return {
      accessToken,
      name,
      lastName,
      email,
    };
  } else {
    // else cognito provider then only return accessToken as we can
    // get name, last name and email from login data
    return {
      accessToken,
    };
  }
}
// ------------------------------------------------------------------------
