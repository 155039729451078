/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-west-2",
  aws_cognito_identity_pool_id:
    "us-west-2:90f9b057-2f74-422b-b816-15b0e967c3aa",
  aws_cognito_region: "us-west-2",
  aws_user_pools_id: "us-west-2_0W9o1tnpf",
  aws_user_pools_web_client_id: "259phosenc9k9mltniai4ag8ae",
  oauth: {
    domain:
      "homepagev3release9f2477f1-9f2477f1-release.auth.us-west-2.amazoncognito.com",
    scope: ["email", "openid", "profile"],
    redirectSignIn: "https://webappdemo.ideamapper.com/",
    redirectSignOut: "https://webappdemo.ideamapper.com/",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: ["FACEBOOK", "GOOGLE"],
  aws_cognito_signup_attributes: ["EMAIL", "FAMILY_NAME", "GIVEN_NAME"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
};

export default awsmobile;
