/* -*- JS -*- */
// ------------------------------------------------------------------------
//
// This code is property of BioSilico Limited and is protected by
// copyright law and international treaties. Unauthorized
// reproduction or distribution of this file, or any portion of
// it may result in severe civil and criminal penalties, and will
// be prosecuted to the maximum extent possible under the law.
//
// = LIBRARY
//   HomepageJS
//
// = FILENAME
//   App.jsx
//
// = AUTHOR
//   Who       When        Why/What
//   K. Plewa   09.11.2022  Development
//
// ------------------------------------------------------------------------

import "./App.css";
import { Route, Switch } from "react-router-dom";
import React from "react";
import IdeaMapper from "./wasm";
import LoginComponent from "./components/Login/LoginComponent";
import CleverLogIn from "./components/Login/CleverLogIn";
import ClassLinkLogIn from "./components/Login/ClassLinkLogIn";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import CookieConsent from "react-cookie-consent";
import WasmReactComponent from "./components/wasmReactComponent/WasmReactComponent";
import reactAppVars from "./resources/ReactAppVariables.json";
import {
  isMobile,
  isAndroid,
  isFirefox,
  isIE,
  isLegacyEdge,
} from "mobile-device-detect";

// ------------------------------------------------------------------------
// Main App Class
export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.checkPageFocus();
    // Set login pages height as global css var
    var r = document.querySelector(":root");
    r.style.setProperty("myHeight", "calc(max-content + 5%");
    // Global var to set location of the WASM
    // Set up states to be used in the app
    this.state = {
      showSignInPage: true,
      signInStatusApp: false,
      configLoaded: false,
      winRemoveListener: false,
    };
    // Configure the AWS Amplify
    Amplify.configure(awsExports);
    // Call the startup function after set up
  }

  // ---------------------------------------------------------------------------
  componentDidMount() {
    var canLoadConfig = true;
    // Check if the user is using mobile device.
    if (isMobile === true) {
      // Have to block mobile devices on Firefox as is unstable.
      if (isFirefox === true) {
        canLoadConfig = false;
        // Allow mobile devices that are Android but show message to manage expectations.
      } else if (isAndroid === true) {
        window.alert(
          "Thank you for trying ideamapper on your mobile device or tablet. Please note that there are issues on this device which may make it quite difficult to use the application but it may just work fine… “your mileage may vary  😉”…"
        );
      } else {
        // Any other mobile devices show message that not available.
        canLoadConfig = false;
        window.alert(
          "We're sorry but IdeaMapper is currently not available for mobile.\nPlease switch to a desktop device.\nWe are working hard to get a mobile version."
        );
      }
    }
    // If passed mobile check check if the user is on correct browser and send message if not
    if (canLoadConfig === true) {
      if (isIE || isLegacyEdge) {
        canLoadConfig = false;
        window.alert(
          "Please switch browsers. Your browser is currently not supported."
        );
      }
    }
    // If passed all checks allow the user to go through to start up.
    if (canLoadConfig === true) {
      this.startup();
    }
  }

  // ----------------------------------------------------------------------------
  async startup() {
    // Set page title from Application Variables
    document.title = reactAppVars.PAGE_TITLE;
    // Get data from config file
    const {
      isOnline,
      offlineMessage,
      offlineUntil,
      userCloudAPI,
      wasmLocation,
      wasmVersion,
    } = await window.getConfigs(reactAppVars.MAIN_CONFIG_URL);
    // First check if cloud is online
    if (!isOnline) {
      //If offline stop page loading and give user the message.
      this.cloudIsOffline(offlineUntil, offlineMessage);
      return;
    } else if (wasmLocation !== undefined && userCloudAPI !== undefined) {
      // Set variables for the app
      sessionStorage.setItem("wasmLocation", wasmLocation);
      sessionStorage.setItem("wasmVersion", wasmVersion);
      sessionStorage.setItem("userCloudURL", userCloudAPI);
      this.setState({ configLoaded: true });
    }
  }

  // ----------------------------------------------------------------------------
  setShowSignInPage(truefalse) {
    this.setState(
      { showSignInPage: truefalse, signInStatusApp: !truefalse },
      () => console.log()
    );
  }

  // ----------------------------------------------------------------------------
  //Function PURELY for TESTING to skip logging in and go straight to WASM
  testLogIn() {
    console.log("testLogIn");
    this.setState({ showSignInPage: false, signInStatusApp: true });
  }

  // ----------------------------------------------------------------------------
  //If scheduled offline stop page loading and give user the message.
  cloudIsOffline(offlineUntil, offlineMessage) {
    // Stop further loading of webpage
    window.stop();
    // Message the user
    alert(
      `The cloud is currently offline, we apologize for any inconvenience! The cloud will be offline until ${offlineUntil}.\nAdditional message: ${offlineMessage}.`
    );
  }

  // ----------------------------------------------------------------------------
  checkResponse() {
    alert("Are you sure you want to leave?");
  }

  // ----------------------------------------------------------------------------
  checkPageFocus() {
    const canvas = document.getElementById("qtcanvas");
    canvas.addEventListener("blur", (event) => {
      canvas.addEventListener("click", (event) => {
        canvas.focus();
      });
    });
  }
  // ----------------------------------------------------------------------------
  // Main render
  render() {
    return (
      <Switch>
        <div className="App">
          <WasmReactComponent />
          <Route exact path="/">
            <LoginComponent
              signInStatusApp={this.state.signInStatusApp}
              showSignInPage={this.state.showSignInPage}
              setShowSignInPage={this.setShowSignInPage.bind(this)}
              testLogIn={this.testLogIn.bind(this)}
              userCloudAPI={sessionStorage.getItem("userCloudAPI")}
              configLoaded={this.state.configLoaded}
            />
            {this.state.signInStatusApp && this.state.configLoaded && (
              <IdeaMapper
                wasmsrcurl={reactAppVars.WASM_SRC_URL}
                wasmemscriptenstring={reactAppVars.WASM_EMSCRIPTEN_STRING}
              />
            )}
          </Route>
          <Route exact path="/:classlink">
            {this.state.configLoaded && (
              <ClassLinkLogIn
                setShowSignInPage={this.setShowSignInPage.bind(this)}
              />
            )}
            {this.state.signInStatusApp && <IdeaMapper />}
          </Route>
          <Route exact path="/:clever">
            {this.state.configLoaded && (
              <CleverLogIn
                setShowSignInPage={this.setShowSignInPage.bind(this)}
              />
            )}
          </Route>
          <CookieConsent
            location="bottom"
            buttonText="I understand"
            cookieName="ideamapper"
            style={{ background: "#808080" }}
            buttonStyle={{
              background: "#f2b280",
              color: "#000",
              fontSize: "14px",
            }}
            expires={30}
          >
            This website uses cookies to enhance the user experience, to read
            more please{" "}
            <a href="https://support.ideamapper.com/index.php/cookie-policy">
              click here
            </a>
            .
          </CookieConsent>
        </div>
      </Switch>
    );
  }
}
// ----------------------------------------------------------------------------
