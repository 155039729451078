/* -*- JS -*- */
// ------------------------------------------------------------------------
//
// This code is property of BioSilico Limited and is protected by
// copyright law and international treaties. Unauthorized
// reproduction or distribution of this file, or any portion of
// it may result in severe civil and criminal penalties, and will
// be prosecuted to the maximum extent possible under the law.
//
// = LIBRARY
//   HomepageJS
//
// = FILENAME
//   LoginComponent.jsx
//
// = AUTHOR
//   Who       When        Why/What
//   K. Plewa   09.11.2022  Development
//
// ------------------------------------------------------------------------

import React from "react";
import Modal from "react-modal";
import "./LoginComponent.css";
import exchangeUserToken from "../../resources/exchangeUserToken";
import exchange from "../../resources/exchange";
import MicrosoftLogin from "react-microsoft-login";
import OauthPopup from "react-oauth-popup";
import "@aws-amplify/ui-react/styles.css";
import LogInWithClever from "../../images/LogInWithClever.png";
import AuthenticatorComponent from "./AuthenticatorComponent";
import $ from "jquery";
import { isBrowser, isMobile } from "mobile-device-detect";

// ------------------------------------------------------------------------
export default class LoginComponent extends React.Component {
  constructor() {
    super();
    this.showOtherProviders = false;
  }

  // ------------------------------------------------------------------------
  componentDidMount() {
    Modal.setAppElement("#root");
  }
  // ------------------------------------------------------------------------
  // Microsoft Login - needs finisinh on azure side
  azureSuccess = async (error, authData) => {
    console.log(error);
    console.log(authData);
    // Needs to be ID token and need to see if we can get name, last name and email from AuthData
    const token = authData?.accessToken;
    const login = "azure";
    // exchange idToken for ideaMapperToken and catch any errors as soon as possible
    const { accessToken, name, lastName, email } = await exchangeUserToken(
      token,
      login
    ).catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log(error.config);
      return null;
    });
    if (accessToken !== null && accessToken !== undefined) {
      this.props.setShowSignInPage(false);
      window.saveDataForWASM(accessToken, email, name, lastName);
    }
  };

  // ------------------------------------------------------------------------
  hideSignIn() {
    this.props.setShowSignInPage(false);
  }

  // ------------------------------------------------------------------------
  onCodeClassLink = async (response) => {
    console.log("onCodeClassLink");
    console.log(response);
    // Need to try and get below data from exchange - might need to do
    // exchange then exchangeToken, to get all data.

    const { accessToken } = await exchange(response, "classlink").catch(
      function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
        return null;
      }
    );
    console.log(`accessToken ${accessToken}`);
    if (accessToken !== null || accessToken !== undefined) {
      window.saveDataForWASM(accessToken);
      this.props.setShowSignInPage(false);
    }
  };

  // ------------------------------------------------------------------------
  onCloseClassLink(response) {
    console.log("onCloseClassLink");
    console.log(response);
  }

  // ------------------------------------------------------------------------
  onCodeClever = async (response) => {
    console.log("onCodeClever");
    console.log(response);
    // Need to try and get below data from exchange - might need to do
    // exchange then exchangeToken, to get all data.
    const { accessToken } = await exchange(response, "clever").catch(function (
      error
    ) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log(error.config);
      return null;
    });
    if (accessToken !== null || accessToken !== undefined) {
      console.log(`accessToken ${accessToken}`);
      window.saveDataForWASM(accessToken);
      this.props.setShowSignInPage(false);
    }
  };

  // ------------------------------------------------------------------------
  onCloseClever(response) {
    console.log("onCloseClever");
    console.log(response);
  }

  // ------------------------------------------------------------------------
  // Pass the token email and users name and surname to main.js to be saved
  // and passed to wasm after it has been loaded
  passDataToWASM = async (data) => {
    console.log(data);
    // Exchange

    const accessToken = await this.onCognitoSuccess(
      data.signInUserSession.idToken.jwtToken
    ).catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log(error.config);
    });
    if (accessToken !== null || accessToken !== undefined) {
      window.saveDataForWASM(
        "cognito",
        data.signInUserSession.idToken.jwtToken,
        accessToken
      );
    }
  };
  // ------------------------------------------------------------------------
  // Exhange cognito token for Ideamapper token
  onCognitoSuccess = async (idToken) => {
    // Do await and catch errors within the await to get most data and
    // allow for better error handling depending on error
    const { accessToken } = await exchangeUserToken(idToken, "cognito").catch(
      function (error) {
        return error;
      }
    );
    return accessToken;
  };

  // ------------------------------------------------------------------------
  // Check whether the user is logged in or not and either show or hide the
  // the login modal
  checkShouldDisplay() {
    if (sessionStorage.getItem("userLoggedIn")) {
      return "none";
    } else {
      return "block";
    }
  }
  // ------------------------------------------------------------------------
  // Helper function to show/hide AWS Cognito if screen width is bigger than
  // 675px
  screenSize() {
    if (window.screen.availWidth >= 676) {
      $(".amazon").attr("style", "display: block;");
      $(".signin_classLink").attr("style", "display: block;");
      $(".signin_clever").attr("style", "display: block;");
    } else if (
      window.screen.availWidth <= 675 &&
      this.showOtherProviders === true
    ) {
      $(".amazon").attr("style", "display: none;");
      $(".signin_classLink").attr("style", "display: block;");
      $(".signin_clever").attr("style", "display: block;");
    }
  }
  // ------------------------------------------------------------------------
  // Function to show/hide AWS Cognito/other providers for small screen users.
  changeProvider(show) {
    this.showOtherProviders = show;
    if (show === true) {
      $(".amazon").attr("style", "display: none;");
      $(".signin_classLink").attr("style", "display: block;");
      $(".signin_clever").attr("style", "display: block;");
      $(".otherProvider button").click($(this).blur());
    } else {
      $(".amazon").attr("style", "display: block;");
      $(".signin_classLink").attr("style", "display: none;");
      $(".signin_clever").attr("style", "display: none;");
      $(".otherProvider button").click($(this).blur());
    }
  }

  // ------------------------------------------------------------------------
  render() {
    // Special custom style for the modal
    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "101%",
        height: "101%",
        maxWidth: "100%",
        transition: "all 5s ease",
        background: "rgba(195, 195, 195, 1)",
        display: `${this.checkShouldDisplay()}`,
      },
    };
    window.addEventListener("resize", () => this.screenSize());

    return (
      <Modal
        isOpen={this.props.showSignInPage}
        style={customStyles}
        ariaHideApp={false}
      >
        {this.props.configLoaded === true && (
          <div className="signIn_container">
            <div className="signIn_main">
              <h3> Please sign in to use IdeaMapper</h3>
              {/* <MicrosoftLogin
                className="signin_microsoft"
                clientId={process.env.REACT_APP_MICROSOFT_APP_ID}
                authCallback={this.azureSuccess}
                tenantUrl={process.env.REACT_APP_MICROSOFT_URL}
                graphScopes={["email", "openid", "profile", "User.Read"]}
                debug={true}
              /> */}
              <div className="signin_clever">
                <div

                // url={process.env.REACT_APP_CLEVER_URL}
                // onCode={this.onCodeClever}
                //onClose={this.onCloseClever}
                >
                  <img
                    className="overlayGray"
                    src={LogInWithClever}
                    alt=""
                  ></img>
                </div>
              </div>
              <div className="signin_classLink">
                <div
                  className="overlayGray"
                  //url={process.env.REACT_APP_CLASSLINK_TEST_URL}
                  // onCode={this.onCodeClassLink}
                  //onClose={this.onCloseClassLink}
                >
                  ClassLink
                </div>
              </div>
              <div className="amazon">
                <AuthenticatorComponent
                  passBack={this.passDataToWASM.bind(this)}
                  setShowSignInPage={this.props.setShowSignInPage}
                  className="amazon"
                ></AuthenticatorComponent>
              </div>
              <div className="otherProvider">
                <button
                  onClick={() => this.changeProvider(!this.showOtherProviders)}
                >
                  Click to switch Login provider
                </button>
              </div>
              <div className="GDPR_Policy">
                By clicking "Logging In" you agree to the GPDR Policy, to read
                more{" "}
                <a href="https://support.ideamapper.com/index.php/gdpr-policy">
                  click here
                </a>
                .
              </div>
            </div>
          </div>
        )}
        {/* {this.props.configLoaded === false && isMobile === true && (
          <div className="signIn_container">
            <div className="signIn_main">
              <div className="underConstruction">
                <h2>
                  We apologize for any inconvenience, but we are working hard to
                  get the mobile version, so you can enjoy IdeaMapper anywhere!
                </h2>
              </div>
            </div>
          </div>
        )}
        {this.props.configLoaded === false && isBrowser === true && (
          <div className="signIn_container">
            <div className="signIn_main">
              <div className="underConstruction">
                <h2>
                  We apologize for any inconvenience, but your browser is
                  unsupported. Please switch to a different browser.
                </h2>
              </div>
            </div>
          </div>
        )} */}
      </Modal>
    );
  }
}
// ------------------------------------------------------------------------
