/* -*- JS -*- */
// ------------------------------------------------------------------------
//
// This code is property of BioSilico Limited and is protected by
// copyright law and international treaties. Unauthorized
// reproduction or distribution of this file, or any portion of
// it may result in severe civil and criminal penalties, and will
// be prosecuted to the maximum extent possible under the law.
//
// = LIBRARY
//   HomepageJS
//
// = FILENAME
//   ClassLinkLogIn.jsx
//
// = AUTHOR
//   Who       When        Why/What
//   K. Plewa   09.11.2022  Development
//
// ------------------------------------------------------------------------

import React from "react";
import exchange from "../../resources/exchange";
// ------------------------------------------------------------------------
// Create a separate component from Amplify Authenticator to prevent a render within a
// render in the LoginComponent that is caused by Amplify Authenticator
export default class ClassLinkLogIn extends React.Component {
  constructor() {
    super();
  }
  // ------------------------------------------------------------------------
  componentDidMount() {
    if (window.location.pathname === "/classlink") {
      localStorage.clear();
      const code = this.getAuthCode();
      if (code !== null || code !== undefined) {
        this.onCodeClassLink(code);
      } else {
        alert(
          "Something went wrong with logging you in.\nPlease try again, if problem persists, please contact support."
        );
      }
    }
  }
  // ------------------------------------------------------------------------
  getAuthCode() {
    var url = new URL(window.location);
    return url.searchParams.get("code") || undefined;
  }
  // ------------------------------------------------------------------------
  onCodeClassLink = async (response) => {
    // Need to try and get below data from exchange - might need to do
    // exchange then exchangeToken, to get all data.

    const { accessToken } = await exchange(response, "classlink").catch(
      function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log(`Error${error.message}`);
        }
        console.log(error.config);
        return null;
      }
    );
    if (accessToken !== null || accessToken !== undefined) {
      console.log(`accessToken ${accessToken}`);
      window.saveDataForWASM("classlink", "classlink", accessToken);
      this.props.setShowSignInPage(false);
    }
  };

  // ------------------------------------------------------------------------
  onCloseClassLink(response) {
    console.log("onCloseClassLink");
    console.log(response);
  }

  // ------------------------------------------------------------------------
  render() {
    return <div></div>;
  }
}
// ------------------------------------------------------------------------
