/* -*- JS -*- */
// ------------------------------------------------------------------------
//
// This code is property of BioSilico Limited and is protected by
// copyright law and international treaties. Unauthorized
// reproduction or distribution of this file, or any portion of
// it may result in severe civil and criminal penalties, and will
// be prosecuted to the maximum extent possible under the law.
//
// = LIBRARY
//   HomepageJS
//
// = FILENAME
//   exchange.js
//
// = AUTHOR
//   Who       When        Why/What
//   K. Plewa   09.11.2022  Development
//
// ------------------------------------------------------------------------
import axios from "axios";

// ------------------------------------------------------------------------

export default async function exchange(code, provider) {
  let url = sessionStorage.getItem("userCloudURL").toString() + "exchange";
  let tokenData = {
    code: code,
    provider: provider,
  };

  let config = {
    method: "post",
    url: url,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: tokenData,
  };

  const {
    data: { accessToken },
  } = await axios(config).catch(function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
    return error;
  });
  console.log(accessToken);
  //   if (provider === "clever") {
  //     let { name, lastName } = parseJwt(accessToken);

  return {
    accessToken,
    // name,
    //lastName,
  };
  //   } else {
  //     return {
  //       accessToken,
  //     };
  //   }
}

// ------------------------------------------------------------------------
