/* -*- JS -*- */
// ------------------------------------------------------------------------
//
// This code is property of BioSilico Limited and is protected by
// copyright law and international treaties. Unauthorized
// reproduction or distribution of this file, or any portion of
// it may result in severe civil and criminal penalties, and will
// be prosecuted to the maximum extent possible under the law.
//
// = LIBRARY
//   HomepageJS
//
// = FILENAME
//   index.js
//
// = AUTHOR
//   Who       When        Why/What
//   K. Plewa   09.11.2022  Development
//
// ------------------------------------------------------------------------
import React from "react";
import ReactDOM from "react-dom";
import App from "./App.jsx";
import { BrowserRouter as Router } from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
